/* import google font */
/* @import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;900&family=Rubik:wght@400;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200..1000&display=swap'); */

/* custom values */
:root {
  --text: #c9c9c9;
  --background: #000000;
  --accent: #b1b1b1;
  --secondary: #131313;
  --utility_color: #1fa8e7;
  --primary: #084f70;
  --grid_display: repeat(auto-fit, minmax(min(15rem, calc(100% - 1rem)), 1fr));
  --ff_h1: 'Rubik', sans-serif;
  --fs_small: .8rem;
  --spacing: 1rem;
  --width: min(80rem, calc(100% - 1.5rem));
  --full_width_padd: 1em;
}


/* css page reset */
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: boldFamily;
  src: url('century-gothic/century-gothic-bold.otf');
}

@font-face {
  font-family: regularFamily;
  src: url('century-gothic/century-gothic.otf');
}

/* element defaults */
html {
  scroll-behavior: smooth;
  font-family: regularFamily;
}

body {
  font-size: 1rem;
  line-height: 1.5;
  position: relative;
}

article {
  padding-block: 4rem;
}

nav {
  text-transform: uppercase;
}

header p {
  margin-top: var(--spacing);
  margin-bottom: 2rem;
}

ul {
  list-style-type: none;
}

a {
  color: var(--background);
  text-decoration: none;
}

a:not(.cta):hover,
a:not(.cta):focus {
  color: var(--utility_color);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: boldFamily;
  margin-bottom: .5rem;
}

h1 {
  font-optical-sizing: auto;
  font-weight: 800;
  font-variation-settings: "slnt" 0;
  font-size: clamp(1.5rem, 1vw + 1.5rem, 3.5rem);
  line-height: .9;

  @media (min-width: 700px) {
    font-size: clamp(2rem, 2vw + 2rem, 4rem);
  }
}

h2 {
  font-size: clamp(1.3rem, 1vw + 1.3rem, 2rem);
  color: var(--secondary);
  line-height: .9;

  @media (min-width: 700px) {
    font-size: clamp(2rem, 2vw + 2rem, 3rem);
  }
}

p {
  color: var(--secondary);
}

img {
  max-width: 100%;
  height: auto;
  display: block;
}

button {
  background: var(--primary);
  color: var(--background);
  padding: var(--spacing) 1.25rem;
  border: none;
  border-radius: .5rem;
  cursor: pointer;
}

i {
  cursor: pointer;
  color: var(--primary);
}

q {
  font-size: 1.3rem;
  color: var(--secondary);
}

details {
  border-radius: .3rem;
  border: .1rem solid var(--background);
  padding: var(--spacing);
  cursor: pointer;
}

details p {
  margin-block: 2rem;
}

fieldset {
  padding: var(--spacing);
}

legend {
  font-size: 2.5rem;
  color: var(--primary);
}

input {
  padding: .8rem;
  width: 50%;
  border-radius: .5rem;
  border: .1rem solid;
}

input:focus {
  outline: .2rem solid var(--primary);
}

textarea {
  border-radius: .5rem;
}

textarea:focus {
  outline: .2rem solid var(--primary);
}

hr {
  width: 30%;
  border: .2rem solid var(--utility_color);
  margin-bottom: 2rem;
}


/* nav menu */
.nav-link {
  display: none;
}

.fa-bars {
  display: static;
  color: var(--utility_color);
}

.mobile-nav {
  background-color: var(--text);
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1;
  min-height: 100vh;
  min-height: 100dvh;
  width: 100%;
  display: flex;
  flex-flow: column wrap;
  padding: 2rem;
  gap: var(--spacing);
  transform: translateX(100%);
}

.mobile-nav li+li {
  margin-top: 1rem;
  margin-left: 0;
}

.mobile-nav i {
  align-self: flex-end;
  color: red;
}

.mobile-link {
  color: var(--text);
  transition: color 250ms;
}

.mobile-link:hover,
.mobile-link:focus {
  color: var(--utility_color);
}

.sticky-nav {
  background: white;
  overflow: hidden;
  padding-inline: 3rem;
}

.content {
  padding: 16px;
}

.sticky {
  position: fixed;
  top: 0;
  z-index: 2;
  width: 100%;
}

.sticky+.content {
  padding-top: 60px;
}


/* hero section */
.hero-section {
  position: relative;
  padding-block: 0 0;
}

.hero-section::before {
  content: '';
  position: absolute;
  inset: 0;
  z-index: -1;
  background: linear-gradient(to top, #084f704d 20%, transparent 50%);
}

.hero-section header {
  justify-self: center;
  padding-inline: 1rem;
  grid-column: 1 / -1;

  @media (min-width: 1024px) {
    grid-column: unset;
    padding-inline: 6rem;
  }
}

.hero-section .hero-img {
  grid-column: 1 / -1;

  @media (min-width: 1024px) {
    grid-column: unset;
    background: url('img/hero-img.jpeg') no-repeat;
    background-size: cover;
  }
}

.hero-section .hero-img>img {
  @media (min-width: 1024px) {
    display: none;
  }
}

.main-header-content {
  margin-top: 5rem;
  margin-bottom: 6rem;
}

.unlock {
  display: flex;
  align-items: center;
}

.main-header-content span {
  display: inline-block;
  border: .3rem solid var(--utility_color);
  padding: 1rem;
  font-size: clamp(2.2rem, 2vw + 2.2rem, 4rem);
}

.main-header-content :first-child,
.main-header-content :nth-child(2),
.main-header-content :nth-child(3),
.main-header-content :nth-child(4) {
  grid-column: 1 / -1;

  @media (min-width: 1024px) {
    grid-column: unset;

  }
}

.hero-service-list {
  margin-bottom: 3rem;
  font-size: .8rem;
  color: var(--background);
}

.hero-service-list-display {
  display: flex;
  gap: .5rem;
}

.hero-service-list-display :nth-child(2) {
  align-self: center;
}


/* brands section */
.brands-wrapper {
  display: flex;
  align-items: center;
  gap: 2rem;
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.brands-wrapper::-webkit-scrollbar {
  display: none;
}

.brands-wrapper>* {
  flex-grow: 1;
  flex-shrink: 0;
}


/* cards component */
.cards {
  width: var(--width);
  margin-inline: auto;
}

.cards>header {
  width: var(--width);
  margin-inline: auto;
  text-align: center;
}

.cards-wrapper>* {
  flex-grow: 1;
  flex-basis: calc(100rem - 100%);
}

.cards-wrapper>*>:first-child,
.cards-wrapper>*>:nth-child(2) {
  grid-column: 1 / 1;
  grid-row: 1 / 1;
}

.cards-wrapper>*>:first-child {
  padding: 2rem 1rem 1rem;
  z-index: 1;
  background: #0a0a0a98;
  color: var(--utility_color);
}

.cards-wrapper>* h3 {
  font-size: 2rem;
  line-height: 1.1;
}

.cards-wrapper>* p {
  color: var(--text);
}

.cards-wrapper>* :first-child {
  align-self: end;
}


/* contact form */
.contact-form {
  width: var(--width);
  margin-inline: auto;
}

.form-input {
  display: flex;
  flex-flow: column;
}

.form-input+.form-input {
  margin-top: var(--spacing);
}


/* footer style */
footer {
  background: var(--text);
}

footer :first-child {
  @media (min-width: 700px) {
    grid-column: span 2;
  }
}

footer :last-child {
  grid-column: 1 / -1;
}

footer i {
  margin-right: .5rem;
}

footer span {
  font-size: var(--fs_small);
}

footer h3 {
  padding-top: var(--spacing);
  color: var(--primary);
}

.copyright {
  text-align: center;
  font-size: .9rem;
  padding-top: var(--spacing);
  color: var(--primary);
}


/* slider component */
.slider-col-component {
  position: relative;
  background: #084f7025;
}

.slider-col-component header {
  max-width: 65ch;
  margin-inline: auto;
  text-align: center;
}

.scroll-buttons {
  display: flex;
  gap: .2rem;
  margin-bottom: 4rem;
  margin-inline: auto;

  @media (min-width: 1024px) {
    width: 50%;
  }
}

.left-arrow,
.right-arrow {
  background: var(--primary);
  padding: .5rem;
  border-radius: 50%;
  color: white;
}


/* testimonial section */
.testimonial-wrapper {
  position: relative;
  margin-top: 3rem;
  padding: 1rem;
  /* overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none; */
}

.testimonial-wrapper::-webkit-scrollbar {
  display: none;
}

.testimonial-card h3 {
  font-size: 1rem;
}

.company-name {
  margin-inline: auto;
  margin-top: 4rem;
  color: var(--primary);
  font-size: 1.3rem;

  @media (min-width: 1024px) {
    width: 50%;
  }
}

blockquote {
  border: none;
  margin-bottom: -30px;
  position: relative;
  max-width: 40rem;
  margin: 0 auto;
  padding-left: 0.5em;
  font-family: var(--font_f);
}

blockquote p {
  font-size: 1.3rem;
  color: var(--text_clr);
}

blockquote p:before {
  content: "\201C";
  font-weight: 500;
  font-size: 7rem;
  color: var(--primary);
  position: absolute;
  top: -0.6em;
  left: -0.1em;
  font-family: 'Georgia';
}

blockquote p:after {
  content: "\201D";
  font-weight: 500;
  font-size: 7rem;
  color: var(--primary);
  position: absolute;
  bottom: -1em;
  right: -0.1em;
  font-family: 'Georgia';
}

.testimonial-card+.testimonial-card {
  margin-left: 2rem;
}


/* faq */
#faq header {
  text-align: center;
}

.faq>* {
  align-self: start;
}


/* utility classes */
.container {
  width: var(--width);
  margin-inline: auto;
}

.bg-dark {
  background: var(--background);
}

.app {
  padding-block: 0;
}

.full-width {
  padding-inline: var(--full_width_padd);
}

.grid-display {
  display: grid;
  grid-template-columns: var(--grid_display);
  grid-auto-rows: auto;
  gap: var(--spacing);
}

.flex-display {
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
  align-items: center;
  gap: var(--spacing);
}

.cta {
  width: fit-content;
  display: block;
  background: var(--utility_color);
  border: none;
  font-size: 1rem;
  text-transform: capitalize;
  color: white;
  padding: .8rem 1.6rem;
  border-radius: .5rem;
  transition: 250ms;
}

.cta:hover,
.cta:focus {
  background: none;
  border: .1rem solid var(--utility_color);
  color: var(--background);
}

.row {
  flex-grow: 1;
  flex-basis: calc(50rem - 100%);
}

.sub-title {
  font-size: clamp(.8rem, 1.2rem, 1.7rem);
  line-height: 1.1;

  @media (min-width: 700px) {
    font-size: clamp(1rem, 1.5rem, 2rem);
  }
}

.cols-2 {
  width: var(--width);
  margin-inline: auto;
  border-radius: .5rem;
  margin-block: 5rem;
}

.cta-wrapper {
  display: grid;
}

.single-col-component {
  width: var(--width);
  margin-inline: auto;
}

.two-cols-img {
  position: relative;

  background: linear-gradient(315deg,
      var(--primary),
      var(--background));
  padding: .5rem;
  border-radius: .5rem;
}

.hidden-email {
  display: none;
}

.service-list {
  list-style-type: square;
  padding-left: 2rem;
  color: var(--background);
  margin-top: .5rem;
}

.service-list>li+.service-list>li {
  padding-left: 3rem;
}

.slide-in {
  animation: slide-in 550ms ease-in forwards;
  -webkit-animation: slide-in 550ms ease-in forwards;
  -moz-animation: slide-in 550ms ease-in forwards;
  -o-animation: slide-in 550ms ease-in forwards;
}

.zoom-in {
  animation: zoom-in 550ms ease-in-out 1s forwards;
  -webkit-animation: zoom-in 550ms ease-in-out 1s forwards;
  -moz-animation: zoom-in 550ms ease-in-out 1s forwards;
  -o-animation: zoom-in 550ms ease-in-out 1s forwards;
}

.hidden {
  animation: slide-in-out 1s ease-in forwards;
  -webkit-animation: slide-in-out 1s ease-in forwards;
  -moz-animation: slide-in-out 1s ease-in forwards;
  -o-animation: slide-in-out 1s ease-in forwards;
}


/* media queries */
@media screen and (min-width: 700px) {
  .nav-link {
    display: flex;
  }

  nav li+li {
    margin-left: var(--spacing);
  }

  .fa-bars {
    display: none;
  }

  .order1 {
    align-self: start;
    grid-column: 1 / span 1;
    grid-row: 1 / 2;
  }

  .order2 {
    align-self: center;
    grid-column: 2 / span 1;
    grid-row: 1 / 2;
  }

  .cards>header>p {
    width: 70ch;
    margin-inline: auto;
  }

  .slider-col-component header {
    width: 80ch;
    margin-inline: auto;
  }
}


/* animations */
@keyframes slide-in {
  to {
    opacity: 1;
    transform: unset;
  }
}

@keyframes slide-in-out {
  to {
    opacity: 0;
    transform: translateX(100%);
  }
}

@keyframes zoom-in {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
    transform: scale(1.2);
    transform: unset;
  }

  100% {
    opacity: 1;
    transform: unset;
  }
}